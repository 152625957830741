

import Swiper from "swiper";
export default {
    components: {

    },
    data() {
        return {
            // elmentList: [
            //     {
            //         pcElementId: 192,
            //         phoneElementId: 197,
            //         descList: [{
            //             descName: 'Thank you for purchasing and using Sevnce Robotics products. Our company will continue to provide you with high quality after-sales service.'
            //             , sort: 1
            //         }, {
            //             descName: '24-hour after-sales service hotline: 400-8765-755'
            //             , sort: 2
            //         }],
            //         type: 'image',
            //         sort: 1
            //     },
            //     {
            //         pcElementId: 193,
            //         phoneElementId: 198,
            //         descList: [{
            //             descName: 'Product Training Services'
            //             , sort: 1
            //         }],
            //         type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId: 194,
            //         phoneElementId: 199,
            //         descList: [{
            //             descName: 'After-sales Service Within Warranty'
            //             , sort: 1
            //         }]
            //         ,
            //         type: 'image',
            //         sort:3
            //     },
            //     {
            //         pcElementId: 195,
            //         phoneElementId: 200,
            //         descList: [{
            //             descName: 'Paid After-sales Services out of Warranty'
            //         }]
            //         ,
            //         type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId: 196,
            //         phoneElementId: 201,
            //         descList: [{
            //             descName: 'Product Customization Services'
            //         }]
            //     },
            //     {
            //         pcElementId: 203,
            //         phoneElementId: 214,
            //         descList: [{
            //             descName: 'Overview'
            //         }, {
            //             descName: 'Service Contents'
            //         },]
            //     },
            //     {
            //         pcElementId: 204,
            //         phoneElementId: 215,
            //         descList: [{
            //             descName: 'Product Training Services'
            //         }, {
            //             descName: 'Before the official delivery of the product, Sevnce Robotics provides users with a one-day standard product usage training, safety knowledge training, and after-sales knowledge training to ensure that users can master the safe use methods and after-sales service content of the product.'
            //         }, {
            //             descName: 'Appointment for Training'
            //         }]
            //     },
            //     {
            //         pcElementId: 205,
            //         phoneElementId: 216,
            //         descList: [{
            //             descName: 'After-sales Service Within Warranty'
            //         }, {
            //             descName: 'From the official delivery date of the product, the entire machine and accessories are guaranteed free of charge for 1 year by default, and the software is eligible for free standardized upgrades. During the warranty period, professional after-sales service engineers are dispatched every 3-6 months to maintain the products on site. The actual warranty period or maintenance cycle, as well as the replacement content of vulnerable parts, shall be subject to the contract agreement.'
            //         }, {
            //             descName: 'Appointment for Service'
            //         }]
            //     },
            //     {
            //         pcElementId: 206,
            //         phoneElementId: 217,
            //         descList: [{
            //             descName: 'Paid After-sales Services out of Warranty'
            //         }, {
            //             descName: 'After the expiration of the warranty after-sales service period, Sevnce Robotics can continue to provide after-sales services to customers. The specific fees and service forms shall be subject to the contract agreement.'
            //         }, {
            //             descName: 'Appointment for Service'
            //         }]
            //     },
            //     {
            //         pcElementId: 207,
            //         phoneElementId: 218,
            //         descList: [{
            //             descName: 'Product Customization Services'
            //         }, {
            //             descName: "According to the customer's usage needs, customized development or modification of product software and hardware will be carried out, and customization fees will be charged based on specific workload."
            //         }, {
            //             descName: 'Apply Now'
            //         }]
            //     },

            //     {
            //         pcElementId: 208,
            //         phoneElementId: 219,
            //         descList: [{
            //             descName: 'Surface Clean'
            //         }, {
            //             descName: 'Regular Maintenance Contents'
            //         }, {
            //             descName: 'It is recommended that customers regularly maintain their products according to the following maintenance procedures. Maintenance work can improve the stability of the products and extend their service life. Please ensure that the robot is turned off during maintenance.'
            //         },]
            //     },
            //     {
            //         pcElementId: 209,
            //         phoneElementId: 220,
            //         descList: [{
            //             descName: 'Lidar'
            //         }, {
            //             descName: 'Regular Maintenance Contents'
            //         }, {
            //             descName: 'It is recommended that customers regularly maintain their products according to the following maintenance procedures. Maintenance work can improve the stability of the products and extend their service life. Please ensure that the robot is turned off during maintenance.'
            //         },]
            //     },
            //     {
            //         pcElementId: 210,
            //         phoneElementId: 221,
            //         descList: [{
            //             descName: '轮胎'
            //         }, {
            //             descName: 'Regular Maintenance Contents'
            //         }, {
            //             descName: 'It is recommended that customers regularly maintain their products according to the following maintenance procedures. Maintenance work can improve the stability of the products and extend their service life. Please ensure that the robot is turned off during maintenance.'
            //         },]
            //     },
            //     {
            //         pcElementId: 211,
            //         phoneElementId: 222,
            //         descList: [{
            //             descName: '云台'
            //         }, {
            //             descName: 'Regular Maintenance Contents'
            //         }, {
            //             descName: 'It is recommended that customers regularly maintain their products according to the following maintenance procedures. Maintenance work can improve the stability of the products and extend their service life. Please ensure that the robot is turned off during maintenance.'
            //         },]
            //     },
            //     {
            //         pcElementId: 212,
            //         phoneElementId: 223,
            //         descList: [{
            //             descName: '充电桩'
            //         }, {
            //             descName: 'Regular Maintenance Contents'
            //         }, {
            //             descName: 'It is recommended that customers regularly maintain their products according to the following maintenance procedures. Maintenance work can improve the stability of the products and extend their service life. Please ensure that the robot is turned off during maintenance.'
            //         },]
            //     },
            //     {
            //         pcElementId: 213,
            //         phoneElementId: 224,
            //         descList: [{
            //             descName: '充电房'
            //         }, {
            //             descName: 'Regular Maintenance Contents'
            //         }, {
            //             descName: 'It is recommended that customers regularly maintain their products according to the following maintenance procedures. Maintenance work can improve the stability of the products and extend their service life. Please ensure that the robot is turned off during maintenance.'
            //         },]
            //     },
            //     {
            //         pcElementId: 263,
            //         phoneElementId: 263,
            //         descList: [{
            //             descName: "Product After-sales Service Manual"
            //         }]
            //     },
            // ],

            // serviceList: [
            //     {
            //         img: require('./images/pc/service1.jpg'),
            //         phoneElementId: require('./images/mobile/service1.jpg'),
            //         name: 'Product Training Services'
            //     },
            //     {
            //         img: require('./images/pc/service2.jpg'),
            //         phoneElementId: require('./images/mobile/service2.jpg'),
            //         name: 'After-sales Service Within Warranty'
            //     },
            //     {
            //         img: require('./images/pc/service3.jpg'),
            //         phoneElementId: require('./images/mobile/service3.jpg'),
            //         name: 'Paid After-sales Services out of Warranty'
            //     },
            //     {
            //         img: require('./images/pc/service4.jpg'),
            //         phoneElementId: require('./images/mobile/service4.jpg'),
            //         name: 'Product Customization Services'
            //     },
            // ],
            elmentList:[],
            aboutswiper: null,
        }
    },
    created() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
          // 获取当前页面的配置信息
          this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper()
            }, 100);
        });
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        // 初始化轮播图
        aboutSwiper() {
            let that = this;
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        if (that.$store.state.pageset.pcshow) {
                            return '<span class="' + className + '">' + (that.elmentList[index +10].descList[0].descName) + '</span>';
                        } else {
                            return `<span class="${className}"> </span>`;
                        }
                    },
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
                fadeEffect: {
                    crossFade: true  //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                },
            });
        },
        servicePDF() {
            window.open(this.elmentList[16].pcImg);
        }
    }
}